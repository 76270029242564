import { useRouter } from "next/router";
import { useCallback, useState } from "react";

export const usePersistDataInUrl = () => {
  const router = useRouter();
  const [newUrl, setNewUrl] = useState<URL>(new URL(window.location.href));

  const formatQuery = useCallback((data: Record<string, unknown>) => {
    const draftUrl = new URL(
      `${window.location.origin}${window.location.pathname}`
    );
    Object.entries(data).forEach((param) => {
      const [key, value] = param;
      const isArray = Array.isArray(value);
      const hasValue = isArray ? Boolean(value.length) : Boolean(value);

      if (key && hasValue) {
        draftUrl.searchParams.append(
          key,
          isArray ? value.join(",") : (value as string)
        );
      }
    });

    setNewUrl(draftUrl);

    return draftUrl;
  }, []);

  const save = useCallback(
    <
      T extends
        | string
        | number
        | boolean
        | readonly string[]
        | readonly number[]
        | readonly boolean[]
        | null
        | undefined
    >(
      data: Record<string, T>
    ) => {
      const nextUrl = formatQuery(data).toString();
      router.replace(nextUrl, undefined, {
        scroll: true,
      });
      return nextUrl;
    },
    [formatQuery, router]
  );

  const getAll = useCallback(<T,>() => {
    if (!newUrl) {
      setNewUrl(new URL(window.location.href));
    }

    const { searchParams } = newUrl;

    return Object.fromEntries(searchParams) as unknown as T;
  }, [newUrl]);

  const get = useCallback(
    <T extends string | number | boolean>(key: string) => {
      if (!newUrl) {
        setNewUrl(new URL(window.location.href));
      }

      const { searchParams } = newUrl;

      return searchParams.get(key) as unknown as T;
    },
    [newUrl]
  );

  const clear = useCallback(() => {
    const clearedUrl = new URL(
      `${window.location.origin}${window.location.pathname}`
    ).toString();
    router.replace(clearedUrl, undefined, {
      scroll: true,
    });
    return clearedUrl;
  }, [router]);

  const update = useCallback(
    <
      T extends
        | string
        | number
        | boolean
        | readonly string[]
        | readonly number[]
        | readonly boolean[]
        | null
        | undefined
    >(
      key: string,
      value: T
    ) => {
      if (!key) return;

      const draftUrl = new URL(window.location.href);

      // Remove existing value for this key
      draftUrl.searchParams.delete(key);

      // Add new value if it exists
      const isArray = Array.isArray(value);
      const hasValue = isArray ? Boolean(value.length) : Boolean(value);

      if (hasValue) {
        draftUrl.searchParams.append(
          key,
          isArray ? (value as readonly string[]).join(",") : String(value)
        );
      }

      setNewUrl(draftUrl);
      router.replace(draftUrl.toString());
    },
    [router]
  );

  return { save, get, getAll, clear, size: newUrl?.searchParams.size, update };
};
