import { NewOrdersDto } from "typing";
import { createStore } from "../adapter";

type OrderBoxState = {
  isTrackingModalOpen: boolean;
  shouldShowMinimalistProductList: boolean;
  shouldShowVerticalProductList: boolean;
  packageId: number;
  orderHash: string | null;
  setOrderHash: (packageId: string) => void;
  order?: NewOrdersDto | null;
  url?: string;
  isLoading: boolean;
};

type OrderBoxDispatch = {
  setIsTrackingModalOpen: (isTrackingModalOpen: boolean) => void;
  setShouldShowMinimalistProductList: (
    shouldShowMinimalistProductList: boolean
  ) => void;
  setShouldShowVerticalProductList: (
    shouldShowVerticalProductList: boolean
  ) => void;
  setPackageId: (packageId: number) => void;
  setOrderHash: (packageId: string) => void;
  setOrder: (order?: NewOrdersDto | null) => void;
  setUrl: (url: string) => void;
  setIsLoading: (isLoading: boolean) => void;
};

type OrderBoxProps = OrderBoxState & OrderBoxDispatch;

const useOrderStore = createStore<OrderBoxProps>((set, get) => ({
  isTrackingModalOpen: false,
  shouldShowMinimalistProductList: false,
  shouldShowVerticalProductList: false,
  url: "",
  order: null,
  isLoading: true,
  setShouldShowMinimalistProductList: (shouldShowMinimalistProductList) => {
    if (
      get().shouldShowMinimalistProductList !== shouldShowMinimalistProductList
    ) {
      set({ shouldShowMinimalistProductList });
    }
  },
  setShouldShowVerticalProductList: (shouldShowVerticalProductList) => {
    if (get().shouldShowVerticalProductList !== shouldShowVerticalProductList) {
      set({ shouldShowVerticalProductList });
    }
  },
  setIsTrackingModalOpen: (isTrackingModalOpen) => {
    if (get().isTrackingModalOpen !== isTrackingModalOpen) {
      set({ isTrackingModalOpen });
    }
  },
  packageId: 0,
  setPackageId: (packageId) => {
    if (get().packageId !== packageId) {
      set({ packageId });
    }
  },
  orderHash: null,
  setOrderHash: (orderHash) => {
    if (get().orderHash !== orderHash) {
      set({ orderHash });
    }
  },
  setOrder(order) {
    set({ order });
  },
  setUrl(url: string) {
    set({ url });
  },
  setIsLoading(isLoading: boolean) {
    if (get().isLoading !== isLoading) {
      set({ isLoading });
    }
  },
}));

export { useOrderStore };
