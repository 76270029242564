import { FilterSession, MonthFilter } from "typing";
import {
  lastThirtyDaysValue,
  selectDateValue,
  thisYearValue,
} from "../../constraints";
import { capitalize, formatDate } from "../../utils";
import { createStore } from "../adapter";

type GetLabelTextsProps = {
  typedTerm?: string;
  periodOfLastPurchase?: MonthFilter;
};

type FilterState = {
  selectedFilters: string[];
  filtersApplied: string[];
  isOpen: boolean;
  selectedYear: number | null;
  startDate?: string;
  endDate?: string;
  saleChannel?: number;
  status: string;
  isGift?: boolean;
  hasFiltersApplied?: boolean | string;
  showSelectYearDropdown: boolean;
  filters: FilterSession[];
  filtersToSend?: string[];
  currentPage: number;
  labelTexts: {
    resultText: string;
  };
};

type FilterFunction = {
  handleCheckboxChange: (optionText: string, isChecked: boolean) => void;
  applyFilters: (
    isNewFilter?: boolean,
    options?: {
      totalResults?: number;
      monthsReturned?: MonthFilter;
    }
  ) => {
    startDate?: string | undefined;
    endDate?: string | undefined;
    status: string;
    isGift: boolean | undefined;
    saleChannel: number | undefined;
    currentPage: number;
  };
  clearFilters: (options?: {
    totalResults?: number;
    monthsReturned?: MonthFilter;
  }) => void;
  closeFilter: () => void;
  setIsOpen: (value: boolean) => void;
  setFiltersApplied: () => void;
  setSelectedYear: (year: number | null) => void;
  setShowSelectYearDropdown: (isSelected: boolean) => void;
  setFilters: (filters: FilterSession[]) => void;
  setCurrentPage: (page: number) => void;
  removeFilter: (filter: string) => void;
  setLabelTexts: (indicatorText: string) => void;
  defineLabelTexts: (data: GetLabelTextsProps) => void;
  setSelectedFilters: (filters?: string[] | null) => void;
};

const DATE_FILTERS: { [key: number]: string } = {
  0: "",
  1: "ÚLTIMOS 30 DIAS",
  3: "ÚLTIMOS 3 MESES",
  6: "ÚLTIMOS 6 MESES",
  12: "ESTE ANO",
};

type FilterProps = FilterState & FilterFunction;

const getStatus = (status?: string) => {
  switch (status) {
    case "em transporte":
      return "Shipping";
    case "pagamento aprovado":
      return "InProgress";
    case "aguardando pagamento":
      return "Pending";
    case "em manutenção":
      return "Pending";
    case "entregue":
    case "retirado":
      return "Concluded";
    case "cancelado":
      return "Cancelled";
    case "entrega prevista":
    case "pronto para retirada":
      return "Shipping";
    default:
      return "All";
  }
};

const getDate = (date: string, selectedYear: number | null) => {
  const today = new Date();
  const formatDateFilter = date.split("-")?.[0];

  if (lastThirtyDaysValue.includes(formatDateFilter)) {
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return {
      startDate: formatDate(firstDayOfMonth),
      endDate: formatDate(today),
    };
  }

  if (formatDateFilter?.includes("Mês passado")) {
    const firstDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );

    const lastDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    );
    return {
      startDate: formatDate(firstDayOfLastMonth),
      endDate: formatDate(lastDayOfLastMonth),
    };
  }

  if (formatDateFilter?.includes("Últimos 3 meses")) {
    const firstDayOfLastThreeMonths = new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      1
    );

    return {
      startDate: formatDate(firstDayOfLastThreeMonths),
      endDate: formatDate(today),
    };
  }

  if (thisYearValue.includes(formatDateFilter)) {
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    return {
      startDate: formatDate(firstDayOfYear),
      endDate: formatDate(today),
    };
  }

  if (selectDateValue.includes(formatDateFilter) && selectedYear) {
    const firstDayOfYear = new Date(selectedYear, 0, 1);
    const lastDayOfYear = new Date(selectedYear, 11, 31);
    return {
      startDate: formatDate(firstDayOfYear),
      endDate: formatDate(lastDayOfYear),
    };
  }

  return {
    startDate: undefined,
    endDate: undefined,
  };
};

const useFilter = createStore<FilterProps>((set, get) => ({
  removeFilter: (filter) => {
    set((state) => {
      const updatedFilters = state.selectedFilters.filter(
        (selectedFilter) => selectedFilter !== filter
      );

      return {
        selectedFilters: updatedFilters,
      };
    });
  },
  currentPage: 1,
  setCurrentPage(page) {
    set({ currentPage: page });
  },
  handleCheckboxChange: (optionText, isChecked) => {
    set((state) => {
      let updatedFilters = [...state.selectedFilters];

      const categories = [
        { regex: /status/i, name: "status" },
        { regex: /data/i, name: "data" },
        { regex: /onde comprei/i, name: "onde comprei" },
        { regex: /tipo de pedido/i, name: "tipo de pedido" },
      ];

      if (isChecked) {
        const selectedColumn = categories.find(({ regex }) =>
          regex.test(optionText)
        );

        if (selectedColumn) {
          const { regex } = selectedColumn;
          const existingOption = updatedFilters.findIndex((filter) =>
            regex.test(filter)
          );

          if (existingOption !== -1) {
            updatedFilters[parseInt(String(existingOption), 10)] = optionText;
          } else {
            updatedFilters = updatedFilters.filter(
              (filter) => !regex.test(filter)
            );
            updatedFilters.push(optionText);
          }
        }
      } else {
        updatedFilters.splice(updatedFilters.indexOf(optionText), 1);
      }

      return {
        selectedFilters: updatedFilters,
      };
    });
  },
  hasFiltersApplied: false,

  applyFilters: (
    isNewFilter?: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    set((state) => {
      const allValue = isNewFilter ? "Todos" : "All";
      const statusFilter = state.selectedFilters
        .find((value) => value.toLowerCase().includes("status"))
        ?.split("-")?.[0];
      const dateFilter = state.selectedFilters.find((value) =>
        value.includes("Data")
      );
      const orderTypeFilter = state.selectedFilters.find((value) =>
        value.includes("Tipo de pedido")
      );
      const saleChannelFilter = state.selectedFilters.find((value) =>
        value.includes("Onde comprei")
      );

      const filterStatus = isNewFilter
        ? statusFilter
        : getStatus(statusFilter?.toLowerCase());

      const selectedSaleChannel = () => {
        const saleChannel = saleChannelFilter?.split("-")?.[0];

        if (saleChannel === "Loja") {
          return 1;
        }

        if (saleChannel === "Site") {
          return 2;
        }

        if (saleChannel === "App") {
          return 4;
        }

        return undefined;
      };

      const filterCollection = state.selectedFilters
        .filter((filter) => {
          const type = filter.split("-")[1].toLowerCase();
          return type === "status" || type === "onde comprei";
        })
        .map((filter) => filter.split("-")[0]);

      const validateFiltersApplied =
        statusFilter !== allValue ||
        dateFilter ||
        orderTypeFilter ||
        saleChannelFilter;

      if (validateFiltersApplied) {
        state.defineLabelTexts({});
      }

      return {
        status: statusFilter ? filterStatus : allValue,
        isGift: orderTypeFilter
          ? orderTypeFilter.split("-")?.[0]?.toLowerCase() ===
            "lista de casamento"
          : undefined,
        saleChannel: selectedSaleChannel(),
        startDate: dateFilter
          ? getDate(dateFilter, state.selectedYear).startDate
          : undefined,
        endDate: dateFilter
          ? getDate(dateFilter, state.selectedYear).endDate
          : undefined,
        hasFiltersApplied: validateFiltersApplied,
        filtersToSend: filterCollection,
        currentPage: 1,
      };
    });

    return {
      currentPage: get().currentPage,
      status: get().status,
      isGift: get().isGift,
      saleChannel: get().saleChannel,
      startDate: get().startDate,
      endDate: get().endDate,
      filters: get().filtersToSend,
    };
  },
  clearFilters: (options?: {
    totalResults?: number;
    monthsReturned?: MonthFilter;
  }) => {
    set((state) => {
      if (options?.monthsReturned) {
        state.defineLabelTexts({
          periodOfLastPurchase: options?.monthsReturned,
        });
      }

      return {
        selectedFilters: [],
        filtersApplied: [],
        isOpen: false,
        selectedYear: null,
        startDate: undefined,
        endDate: undefined,
        status: "All",
        isGift: undefined,
        saleChannel: undefined,
        currentPage: 1,
        hasFiltersApplied: false,
        filtersToSend: undefined,
      };
    });
  },
  filtersApplied: [],
  setFiltersApplied: () => {
    set((state) => ({ filtersApplied: state.filtersApplied }));
  },
  isOpen: false,
  setIsOpen: (value) => {
    set({ isOpen: value });
  },
  closeFilter: () => {
    set({ isOpen: false });
  },
  selectedYear: null,
  setSelectedYear: (year) => {
    set({ selectedYear: year });
  },
  setFilters(filters) {
    set({ filters });
  },
  filters: [],
  selectedFilters: [],
  startDate: undefined,
  endDate: undefined,
  saleChannel: undefined,
  status: "All",
  isGift: undefined,
  showSelectYearDropdown: false,
  setShowSelectYearDropdown: (isSelected: boolean) => {
    set({ showSelectYearDropdown: isSelected });
  },
  labelTexts: {
    resultText: "",
  },
  setLabelTexts(resultText) {
    set({ labelTexts: { resultText } });
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity
  defineLabelTexts({ periodOfLastPurchase, typedTerm }: GetLabelTextsProps) {
    set((state) => {
      const preSelectedDataFilter = periodOfLastPurchase
        ? DATE_FILTERS[`${periodOfLastPurchase}`]
        : "";

      const dateFilterSelected =
        state.selectedFilters?.filter((filter) =>
          filter.toLowerCase().includes("data")
        )[0] || "";
      const dateFilter = capitalize(dateFilterSelected.split("-")[0]);

      const isSelectYearSelected = Boolean(
        dateFilter?.toLowerCase().includes("selecionar") && state.selectedYear
      );
      const hasTypedTerm = Boolean(
        typedTerm !== "" && typeof typedTerm !== "undefined"
      );
      const hasDateFilterSelected = Boolean(
        !dateFilter.toLowerCase().includes("selecionar") && dateFilter
      );
      const hasPreSelectedDataFilter =
        preSelectedDataFilter !== "" &&
        typeof preSelectedDataFilter !== "undefined";

      const dateIndicatorText = hasTypedTerm
        ? `Resultado para: "${typedTerm}"`
        : isSelectYearSelected
        ? `Resultados para "${state.selectedYear}"`
        : hasDateFilterSelected
        ? dateFilter
        : hasPreSelectedDataFilter
        ? capitalize(preSelectedDataFilter)
        : "Resultado";

      return {
        labelTexts: {
          resultText: dateIndicatorText,
        },
      };
    });
  },
  setSelectedFilters(filters?: string[] | null) {
    if (filters) {
      set({ selectedFilters: filters });
    }
  },
}));

export { useFilter };
